import React from 'react'
import { FileQuestion } from 'lucide-react'
import { useNavigate } from "react-router-dom";

export default function NotFoundPage() {
    const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/home");
  }

  const handleGoContact = () => {
    navigate("/contacto");
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8 text-center">
        <FileQuestion className="mx-auto h-16 w-16 text-blue-500 mb-6" />
        <h1 className="text-2xl font-bold text-gray-900 mb-4">Página No Encontrada</h1>
        <p className="text-gray-600 mb-6">
          Lo sentimos, la página que estás buscando no existe o ha sido movida.
        </p>
        <button
          onClick={handleGoHome}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mb-4"
        >
          Volver a la Página Principal
        </button>
        <p className="text-sm text-gray-500">
          Si crees que esto es un error, por favor{' '}
          <button onClick={handleGoContact} className="text-blue-600 hover:underline">
            contáctanos
          </button>
          .
        </p>
      </div>
    </div>
  )
}