import React, { useState } from "react";
import { postCreateDemoUser } from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";

export default function AddUserModal({ onAddUser, onClose, isOpen }) {
  const [newUser, setNewUser] = useState({
    name: "",
    lastName: "",
    email: "",
    password: "",
    birthDate: "",
  });
  const [error, setError] = useState("");

  const { auth } = useAuth();
  const token = auth?.accessToken;

  const handleNewUserChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    // Basic validation
    if (!newUser.name || !newUser.lastName || !newUser.email || !newUser.password || !newUser.birthDate) {
      setError("Todos los campos son requeridos.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newUser.email)) {
      setError("Por favor, ingrese un correo electrónico válido.");
      return;
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*\d)[a-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/i;
    if (newUser.password.length < 8 || !passwordRegex.test(newUser.password) || newUser.password.length > 50) {
      setError("La contraseña debe tener al menos 8 caracteres, incluir un número y no exceder los 50 caracteres.");
      return;
    }

    try {

      const response = await postCreateDemoUser(token, newUser);

      if (response.status === 201) {
        onAddUser(newUser);
        onClose();
      } else {
        const data = await response.json();
        setError(data.message || "Hubo un error al crear el usuario.");
      }
    } catch (err) {
      setError("Hubo un error al conectar con el servidor. Por favor, inténtelo de nuevo.");
    }
  };

  return (
    <>
    { !isOpen && (<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-y-auto">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full m-4">
        <h2 className="text-2xl font-semibold mb-4 text-[#4058A0]">Agregar Nuevo Usuario</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Nombre</label>
            <input
              type="text"
              id="name"
              name="name"
              value={newUser.name}
              onChange={handleNewUserChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#40B3E8] focus:border-[#40B3E8]"
              required
            />
          </div>
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Apellido</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={newUser.lastName}
              onChange={handleNewUserChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#40B3E8] focus:border-[#40B3E8]"
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Correo Electrónico</label>
            <input
              type="email"
              id="email"
              name="email"
              value={newUser.email}
              onChange={handleNewUserChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#40B3E8] focus:border-[#40B3E8]"
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Contraseña</label>
            <input
              type="password"
              id="password"
              name="password"
              value={newUser.password}
              onChange={handleNewUserChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#40B3E8] focus:border-[#40B3E8]"
              required
            />
          </div>
          <div>
            <label htmlFor="birthDate" className="block text-sm font-medium text-gray-700">Fecha de Nacimiento</label>
            <input
              type="date"
              id="birthDate"
              name="birthDate"
              value={newUser.birthDate}
              onChange={handleNewUserChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#40B3E8] focus:border-[#40B3E8]"
              required
            />
          </div>
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-[#40B3E8] text-white rounded-md shadow hover:bg-[#3BA2D2] transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#40B3E8]"
            >
              Agregar Usuario
            </button>
          </div>
        </form>
      </div>
    </div>)}
    </>
  );
}