import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

export const postLogin = async (email, password) => {
  const LOGIN_URL = `${BASE_URL}/login`;

  try {
    const response = await axios.post(
      LOGIN_URL,
      { email, password },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const postRegister = async (user) => {
  const REGISTER_URL = `${BASE_URL}/register`;

  try {
    const response = await axios.post(
      REGISTER_URL,
      JSON.stringify({
        name: user.firstName,
        lastName: user.lastName,
        birthDate: user.birthDate,
        email: user.email,
        password: user.password,
        plan: user.plan,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateMyUser = async (token, name) => {
  const URL = `${BASE_URL}/user`;

  try {
    const response = await axios.put(URL, JSON.stringify({ name }), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getMyUser = async (token) => {
  const URL = `${BASE_URL}/user`;

  try {
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllUsers = async (token) => {
  const URL = `${BASE_URL}/admin/users`;

  try {
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteUser = async (token, id) => {
  const URL = `${BASE_URL}/admin/users/${id}`;

  try {
    const response = await axios.delete(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const toggleUserStatus = async (token, id) => {
  const URL = `${BASE_URL}/admin/active/${id}`;
  try {
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateUserInfo = async (token, id, email, name, password) => {};

export const handlePrompt = async (
  token,
  question,
  botType,
  isNewConversation,
  threadId
) => {
  const URL = `${BASE_URL}/prompts`;

  try {
    const response = await axios.post(
      URL,
      JSON.stringify({ question, botType, isNewConversation, threadId }),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getPrompts = async (token) => {
  const URL = `${BASE_URL}/prompts`;
  try {
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getPrompt = async (token, id) => {
  const URL = `${BASE_URL}/prompts/${id}`;
  try {
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const postContact = async (name, email, company, message) => {
  const URL = `${BASE_URL}/contact`;

  try {
    const response = await axios.post(
      URL,
      JSON.stringify({ name, email, company, message }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getLaws = async (token) => {
  const URL = `${BASE_URL}/repository/laws`;
  try {
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    console.error("Error fetching laws:", error);
    return error;
  }
};

export const postStats = async (page, action) => {
  const URL = `${BASE_URL}/stats`;

  try {
    const response = await axios.post(URL, JSON.stringify({ page, action }), {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const postForgotPassword = async (email) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/forgot-password`,
      { email: email },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getStatusPayment = async (token) => {
  const URL = `${BASE_URL}/verify/payment-status`;
  try {
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getPaymentInfoUser = async (token) => {
  const URL = `${BASE_URL}/user/payment-info`;
  try {
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getRemoveUserPayment = async (token) => {
  const URL = `${BASE_URL}/user/remove-payment`;
  try {
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getUpdatePayment = async (token) => {
  const URL = `${BASE_URL}/user/update-payment`;
  console.log("HOLA");

  try {
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const postResetPassword = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/reset-password`, {token: data.token, password: data.password}, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const postCreateDemoUser = async (token, newUser) => {
  console.log("newUser", newUser, token);  
  try {
    const response = await axios.post(`${BASE_URL}/admin/create-demo`, newUser, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    console.error("Error creating demo user:", error);  
    return error;
  }
};