import React, { useState, useEffect } from "react";
import { EyeIcon, EyeOffIcon, ArrowLeftIcon } from "lucide-react";
import logo from "../../assets/logo.png";
import { postRegister } from "../../api/axios";
import { postStats } from "../../api/axios";
import { useNavigate, useLocation } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

const plans = {
  basic: { name: "Plan Básico", price: 6990 },
};

const formatPrice = (price) => {
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  }).format(price);
};

export default function SignUpForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    birthDate: "",
    email: "",
    password: "",
    confirmPassword: "",
    plan: "",
  });

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [warningMessage, setWarningMessage] = useState("");

  const { setAuth, persist, setPersist } = useAuth();
  const location = useLocation()
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/verify";

  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  useEffect(() => {
    if (formData.plan) {
      setTotalAmount(plans[formData.plan].price);
    } else {
      setTotalAmount(0);
    }
  }, [formData.plan]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*\d)[a-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/i;
    const nameRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/;

    if (!nameRegex.test(formData.firstName)) {
      newErrors.firstName = "El nombre solo puede contener letras y espacios";
    }
    if (!nameRegex.test(formData.lastName)) {
      newErrors.lastName = "El apellido solo puede contener letras y espacios";
    }
    if (!emailRegex.test(formData.email)) {
      newErrors.email = "El correo electrónico no es válido";
    }
    if (!passwordRegex.test(formData.password)) {
      newErrors.password =
        "La contraseña debe tener al menos 8 caracteres, una letra y un número";
    }
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Las contraseñas no coinciden";
    }
    if (!formData.plan) {
      newErrors.plan = "Debes seleccionar un plan";
    }
    if (!acceptTerms) {
      newErrors.acceptTerms = "Debes aceptar los términos y condiciones";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    postStats("register", "register");
    setWarningMessage(""); // Limpiar cualquier advertencia previa
    setErrors({}); // Limpiar errores previos
    if (validateForm()) {
      try {
        const response = await postRegister(formData);
        console.log("response", response);  
        if(response.status === 409) {
          setWarningMessage("Este correo electrónico ya está registrado. Por favor, inicia sesión o utiliza otro correo.");
        }
        else if (response.status === 201) {
          const accessToken = response?.data?.accessToken;
          saveToLocalStorage("accessToken", accessToken);
          saveToLocalStorage("persist", persist);
          navigate(from, { replace: true });
        }
      } catch (error) {          
          setErrors({
            submit: "Hubo un error al crear la cuenta. Por favor, inténtalo de nuevo.",
          });
      }
    }
  };

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword((prev) => !prev);
    } else {
      setShowConfirmPassword((prev) => !prev);
    }
  };

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#E6F3FF] to-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 relative">
      <button
        onClick={handleGoBack}
        className="absolute top-4 left-4 p-2 rounded-full bg-white hover:bg-gray-100 transition-colors duration-200 shadow-md"
        aria-label="Volver"
      >
        <ArrowLeftIcon className="h-6 w-6 text-gray-600" />
      </button>
      <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-lg">
        <div>
          <img className="mx-auto h-16 w-auto" src={logo} alt="Logo de Lexia" />
          <p className="mt-2 text-center text-sm text-[#2A3E6D]">
            Por favor, ingrese sus datos para crear una cuenta
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          {warningMessage && (
            <div className="mb-4 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
              <div className="flex">
                <svg className="h-5 w-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
                <p>{warningMessage}</p>
              </div>
            </div>
          )}
          <div className="space-y-4">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-[#2A3E6D]"
                >
                  Nombre
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#40B3E8] focus:border-[#40B3E8] sm:text-sm"
                  placeholder="Juan"
                  value={formData.firstName}
                  onChange={handleChange}
                />
                {errors.firstName && (
                  <p className="mt-1 text-xs text-red-500">
                    {errors.firstName}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-[#2A3E6D]"
                >
                  Apellido
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#40B3E8] focus:border-[#40B3E8] sm:text-sm"
                  placeholder="Pérez"
                  value={formData.lastName}
                  onChange={handleChange}
                />
                {errors.lastName && (
                  <p className="mt-1 text-xs text-red-500">{errors.lastName}</p>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="birthDate"
                className="block text-sm font-medium text-[#2A3E6D]"
              >
                Fecha de nacimiento
              </label>
              <input
                id="birthDate"
                name="birthDate"
                type="date"
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#40B3E8] focus:border-[#40B3E8] sm:text-sm"
                value={formData.birthDate}
                onChange={handleChange}
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-[#2A3E6D]"
              >
                Correo electrónico
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#40B3E8] focus:border-[#40B3E8] sm:text-sm"
                placeholder="juan.perez@ejemplo.com"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <p className="mt-1 text-xs text-red-500">{errors.email}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-[#2A3E6D]"
              >
                Contraseña
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="new-password"
                  required
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#40B3E8] focus:border-[#40B3E8] sm:text-sm pr-10"
                  placeholder="********"
                  value={formData.password}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  onClick={() => togglePasswordVisibility("password")}
                >
                  {showPassword ? (
                    <EyeOffIcon className="h-5 w-5 text-gray-400" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-400" />
                  )}
                </button>
              </div>
              {errors.password && (
                <p className="mt-1 text-xs text-red-500">{errors.password}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-[#2A3E6D]"
              >
                Repetir contraseña
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  autoComplete="new-password"
                  required
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#40B3E8] focus:border-[#40B3E8] sm:text-sm pr-10"
                  placeholder="********"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  onClick={() => togglePasswordVisibility("confirmPassword")}
                >
                  {showConfirmPassword ? (
                    <EyeOffIcon className="h-5 w-5 text-gray-400" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-400" />
                  )}
                </button>
              </div>
              {errors.confirmPassword && (
                <p className="mt-1 text-xs text-red-500">
                  {errors.confirmPassword}
                </p>
              )}
            </div>
            <div>
              <label
                htmlFor="plan"
                className="block text-sm font-medium text-[#2A3E6D]"
              >
                Elegir plan
              </label>
              <select
                id="plan"
                name="plan"
                required
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-[#40B3E8] focus:border-[#40B3E8] sm:text-sm rounded-md"
                value={formData.plan}
                onChange={handleChange}
              >
                <option value="">Seleccione un plan</option>
                <option value="basic">Plan Individual</option>
              </select>
              {errors.plan && (
                <p className="mt-1 text-xs text-red-500">{errors.plan}</p>
              )}
            </div>
            {totalAmount > 0 && 
              <div className="text-right">
                <p className="text-sm font-medium text-[#2A3E6D]">
                  Total mensual: {formatPrice(totalAmount)}
                </p>
              </div>
            }
            <div className="flex items-center">
              <input
                id="acceptTerms"
                name="acceptTerms"
                type="checkbox"
                className="h-4 w-4 text-[#40B3E8] focus:ring-[#40B3E8] border-gray-300 rounded"
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
              />
              <label
                htmlFor="acceptTerms"
                className="ml-2 block text-sm text-[#2A3E6D]"
              >
                Acepto los{" "}
                <a
                  href="#"
                  className="text-[#40B3E8] hover:text-[#2A3E6D]"
                  onClick={() => navigate("/terminos-y-condiciones")}
                >
                  términos y condiciones
                </a>
              </label>
            </div>
            {errors.acceptTerms && (
              <p className="mt-1 text-xs text-red-500">{errors.acceptTerms}</p>
            )}
          </div>

          {errors.submit && (
            <p className="mt-2 text-sm text-red-500">{errors.submit}</p>
          )}

          <div>
            <button
              type="submit"
              className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                acceptTerms
                  ? "bg-gradient-to-r from-[#40B3E8] to-[#2A3E6D] hover:from-[#2A3E6D] hover:to-[#40B3E8] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#40B3E8]"
                  : "bg-gray-300 cursor-not-allowed"
              }`}
              disabled={!acceptTerms}
            >
              Crear cuenta
            </button>
          </div>
        </form>
        <div className="text-center">
          <p className="text-sm text-[#2A3E6D]">
            ¿Ya tienes una cuenta?{" "}
            <button
              onClick={() => navigate("/login")}
              className="font-medium text-[#40B3E8] hover:text-[#2A3E6D] focus:outline-none focus:underline transition ease-in-out duration-150"
            >
              Inicia sesión aquí
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}