import React, { useState, useEffect } from "react";
import { Home } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  updateMyUser,
  getAllUsers,
  deleteUser,
  toggleUserStatus,
  postRegister,
  getMyUser,
} from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import UserInfo from "./Perfil/UserInfo";
import AdminPanel from "./Perfil/AdminPanel";

export default function Perfil() {
  const [name, setName] = useState("");
  const [nameTitle, setNameTitle] = useState("");
  const [email, setEmail] = useState("");
  const [plan, setPlan] = useState("");
  const [callsThisMonth, setCallsThisMonth] = useState(0);
  const [maxCallsAllowed, setMaxCallsAllowed] = useState(0);
  const [isEditingName, setIsEditingName] = useState(false);
  const [users, setUsers] = useState([]);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const navigate = useNavigate();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const roles = auth.roles;

  useEffect(() => {
    setName(auth.name);
    setNameTitle(auth.name);
    setEmail(auth.email);
    if (roles.includes(300)) {
      fetchUsers();
    }
    fetchMyUser();
  }, [auth, roles]);

  const fetchUsers = async () => {
    try {
      const response = await getAllUsers(token);
      if (response.status === 200) {
        setUsers(response.data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchMyUser = async () => {
    try {
      const response = await getMyUser(token);

      if (response.status === 200) {
        setPlan(response.data.user.plan);
        setCallsThisMonth(response.data.user.callsThisMonth);
        setMaxCallsAllowed(response.data.user.maxCallsAllowed);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const toggleNameEdit = () => {
    setIsEditingName(!isEditingName);
  };

  const saveName = async () => {
    try {
      const response = await updateMyUser(token, name);
      if (response.status === 200) {
        setNameTitle(name);
        setIsEditingName(false);
      }
    } catch (error) {
      console.error("Error updating name:", error);
    }
  };

  const addUser = async (newUser) => {
    try {
      const response = await postRegister(token, newUser);
      if (response.status === 201) {
        setIsAddingUser(false);
        fetchUsers();
      }
    } catch (error) {
      console.error("Error adding new user:", error);
    }
  };

  const handleDeleteUser = async (userId) => {
    setUserToDelete(userId);
    setIsConfirmingDelete(true);
  };

  const confirmDeleteUser = async () => {
    if (userToDelete) {
      try {
        const response = await deleteUser(token, userToDelete);
        if (response.status === 200) {
          fetchUsers();
        }
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
    setIsConfirmingDelete(false);
    setUserToDelete(null);
  };

  const handleToggleUserStatus = async (userId) => {
    try {
      const response = await toggleUserStatus(token, userId);
      if (response.status === 200) {
        fetchUsers();
      }
    } catch (error) {
      console.error("Error toggling user status:", error);
    }
  };

  const goToHome = () => {
    navigate("/home");
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#E6F7FF] to-[#F0F8FF] py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-6xl mx-auto">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8 space-y-4 sm:space-y-0">
          <button
            onClick={goToHome}
            className="px-4 py-2 bg-[#40B3E8] text-white rounded-md shadow hover:bg-[#3BA2D2] transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#40B3E8] flex items-center"
          >
            <Home className="h-5 w-5 mr-2" />
            Volver al Inicio
          </button>
          <h1 className="text-3xl font-extrabold text-[#4058A0]">
            {auth.roles.includes(300)
              ? "Panel Administrador"
              : auth.roles.includes(200) || auth.roles.includes(100)
              ? "Configuración de Cuenta"
              : "Panel de Usuario"}
          </h1>
        </div>
        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          <UserInfo
            name={name}
            nameTitle={nameTitle}
            email={email}
            plan={plan}
            isEditingName={isEditingName}
            onNameChange={handleNameChange}
            onToggleNameEdit={toggleNameEdit}
            onSaveName={saveName}
            callsThisMonth={callsThisMonth}
            maxCallsAllowed={maxCallsAllowed}
          />
          {roles && roles.includes(300) && (
            <AdminPanel
              users={users}
              onAddUser={addUser}
              onDeleteUser={handleDeleteUser}
              onToggleUserStatus={handleToggleUserStatus}
              isAddingUser={isAddingUser}
              setIsAddingUser={setIsAddingUser}
              isConfirmingDelete={isConfirmingDelete}
              setIsConfirmingDelete={setIsConfirmingDelete}
              confirmDeleteUser={confirmDeleteUser}
              userToDelete={userToDelete}
              authEmail={auth.email}
            />
          )}
        </div>
      </div>
    </div>
  );
}