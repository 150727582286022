import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { getLaws } from '../../api/axios'
import useAuth from "../../hooks/useAuth"

export default function Repository() {
  const [searchTerm, setSearchTerm] = useState('')
  const [laws, setLaws] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [expandedMinistries, setExpandedMinistries] = useState([])

  const navigate = useNavigate()
  const { auth } = useAuth()
  const token = auth.accessToken

  useEffect(() => {
    fetchRepository()
  }, [token])

  const fetchRepository = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const response = await getLaws(token)
      if (response.status === 200) {
        setLaws(response.data)
      }
    } catch (error) {
      console.error("Error fetching laws:", error)
      setError("Hubo un problema al cargar las leyes. Por favor, intente de nuevo más tarde.")
    } finally {
      setIsLoading(false)
    }
  }

  const filteredData = useMemo(() => 
    laws.filter(item => 
      item.NOMBRE.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.TIPO.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.MINISTERIO.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.NUMERO.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [laws, searchTerm]
  )

  const ministries = useMemo(() => 
    Array.from(new Set(filteredData.map(item => item.MINISTERIO))),
    [filteredData]
  )

  const toggleMinistry = (ministry) => {
    setExpandedMinistries(prev => 
      prev.includes(ministry) 
        ? prev.filter(m => m !== ministry)
        : [...prev, ministry]
    )
  }

  const renderLawItem = (item) => (
    <div key={item.NUMERO} className="border-b border-gray-200 pb-4 text-left">
      <h3 className="font-semibold text-blue-600">{item.MINISTERIO}</h3>
      <div className="mt-2 text-gray-600">
        <div className="flex items-center space-x-2">
          <span className="font-semibold">{item.TIPO}</span>
          <span>{item.NUMERO}</span>
        </div>
        <p className="text-sm mt-1">{item.NOMBRE}</p>
        <p className="text-xs text-gray-400 mt-1">Fecha de carga: {item.FECHA_CARGA}</p>
      </div>
    </div>
  )

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-400 to-blue-600 p-8">
      <div className="max-w-4xl mx-auto bg-white rounded-3xl shadow-xl overflow-hidden">
        <div className="p-8">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-3xl font-bold text-blue-600">Repositorio Legal</h1>
            <button 
              onClick={() => navigate('/home')} 
              className="w-10 h-10 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
              aria-label="Volver al inicio"
            >
              🏠
            </button>
          </div>
          <div className="mb-6 relative">
            <input
              type="text"
              placeholder="Buscar leyes..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2 pl-10 border-2 border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-400">🔍</span>
          </div>
          {isLoading ? (
            <div className="text-center py-8">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
              <p className="mt-4 text-gray-600">Cargando leyes...</p>
            </div>
          ) : error ? (
            <div className="text-center py-8">
              <p className="text-red-500">{error}</p>
              <button onClick={fetchRepository} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200">
                Reintentar
              </button>
            </div>
          ) : (
            <div className="space-y-4 max-h-[60vh] overflow-y-auto pr-4">
              {searchTerm ? (
                filteredData.map(renderLawItem)
              ) : (
                ministries.map(ministry => (
                  <div key={ministry} className="border-b border-gray-200 pb-4">
                    <button
                      onClick={() => toggleMinistry(ministry)}
                      className="w-full flex justify-between items-center py-2 px-4 bg-blue-50 hover:bg-blue-100 text-blue-600 rounded-md transition-colors duration-200 text-left"
                    >
                      <span className="font-semibold">{ministry}</span>
                      <span>{expandedMinistries.includes(ministry) ? '▲' : '▼'}</span>
                    </button>
                    {expandedMinistries.includes(ministry) && (
                      <ul className="mt-2 space-y-2">
                        {laws
                          .filter(item => item.MINISTERIO === ministry)
                          .map(item => (
                            <li key={item.NUMERO} className="hover:bg-gray-50 transition-colors duration-200 p-2 rounded-md">
                              {renderLawItem(item)}
                            </li>
                          ))
                        }
                      </ul>
                    )}
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}