import React, { useEffect, useState } from 'react';
import logo from "../../assets/logo.png";
import { getStatusPayment } from '../../api/axios';
import { useNavigate, useLocation } from 'react-router-dom'

const Verify = () => {
    const [token, setToken] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const from = location.state?.from?.pathname || "/login";

    useEffect(() => {
        const storedToken = localStorage.getItem('accessToken');
        if (storedToken) {
            setToken(storedToken);
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (token) {
                getStatusPayment(token)
                    .then(response => {
                        console.log('isActive:', response.data);
                        if(response.data.isActive) {
                            navigate(from, { replace: true });
                        }
                    })
                    .catch(error => {
                        console.error('Error al obtener el estado de pago:', error);
                    });
            }
        }, 3000);

        return () => clearInterval(interval);
    }, [token]);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-[#E6F3FF] to-white text-[#2A3E6D] font-sans text-center px-4">
            <img src={logo} alt="Logo de Lexia" className="w-40 mb-8" />
            <h1 className="text-3xl font-bold text-[#4058A0] mb-2">Verifica tu identidad</h1>
            <p className="text-[#2A3E6D] mb-2">Revisa tu correo para comprobar que eres tú.</p>
            <p className="text-[#2A3E6D] mb-6">Quizás el correo está en tu bandeja de spam.</p>
            <div className="spinner w-12 h-12 border-4 border-[#40B3E8] border-t-transparent rounded-full animate-spin"></div>
        </div>
    );
};

export default Verify;