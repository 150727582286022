import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Menu, LogIn, X } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import logoImage from "../../assets/logo.png";
import { postStats } from "../../api/axios";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = currentScrollPos > 100; // Ajusta este valor según sea necesario
      setIsVisible(visible);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    setIsMobileMenuOpen(false);
  };

  const handleNavigateLogin = () => {
    postStats("landing-page", "login");
    navigate("/login");
    setIsMobileMenuOpen(false);
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.nav
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -100 }}
          transition={{ duration: 0.3 }}
          className="fixed left-0 right-0 top-0 z-50 bg-white bg-opacity-90 shadow-md backdrop-blur-sm"
        >
          <div className="container mx-auto flex h-16 items-center justify-between px-4">
            <Link to="/" className="text-xl font-bold text-[#4058A0]">
              <img
                src={logoImage}
                alt="Lexia Logo"
                className="h-8 w-auto object-contain"
              />
            </Link>
            <div className="hidden items-center space-x-4 md:flex">
              <button
                onClick={() => handleScrollToSection("description-section")}
                className="text-gray-800 hover:text-[#40B3E8]"
              >
                Características
              </button>
              <Link
                to="/contacto"
                className="text-gray-800 hover:text-[#40B3E8]"
              >
                Contacto
              </Link>
              <button
                className="flex items-center rounded-md bg-[#4058A0] px-4 py-2 text-white transition-colors hover:bg-[#40B3E8]"
                onClick={handleNavigateLogin}
              >
                <LogIn className="mr-2 h-4 w-4" />
                Iniciar Sesión
              </button>
            </div>
            <button
              className="rounded-md bg-[#4058A0] px-4 py-2 text-white md:hidden"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              {isMobileMenuOpen ? <X /> : <Menu />}
            </button>
          </div>
          <AnimatePresence>
            {isMobileMenuOpen && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="md:hidden"
              >
                <div className="flex flex-col space-y-2 bg-white p-4">
                  <button
                    onClick={() => handleScrollToSection("description-section")}
                    className="text-left text-gray-800 hover:text-[#40B3E8]"
                  >
                    Características
                  </button>
                  <Link
                    to="/contacto"
                    className="text-gray-800 hover:text-[#40B3E8]"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Contacto
                  </Link>
                  <button
                    className="flex items-center rounded-md bg-[#4058A0] px-4 py-2 text-white transition-colors hover:bg-[#40B3E8]"
                    onClick={handleNavigateLogin}
                  >
                    <LogIn className="mr-2 h-4 w-4" />
                    Iniciar Sesión
                  </button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.nav>
      )}
    </AnimatePresence>
  );
};

export default Navbar;