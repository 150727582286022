import React from 'react'
import { motion } from 'framer-motion'
import { Check, X } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { postStats } from '../../api/axios'

const PricingCard = ({ title, price, originalPrice, discountPercentage, features, isPopular, isComingSoon }) => {
  const navigate = useNavigate();

  const handleSubscribe = () => {
    postStats("landing-page", "basic-plan");
    navigate("/suscripcion")
  }
  
  return (
    <motion.div
      whileHover={{ scale: isComingSoon ? 1 : 1.05 }}
      className={`rounded-xl bg-white p-8 shadow-xl ${
        isPopular ? 'border-4 border-[#40B3E8]' : ''
      } ${isComingSoon ? 'opacity-70' : ''}`}
    >
      {isPopular && (
        <span className="mb-4 inline-block rounded-full bg-[#40B3E8] px-3 py-1 text-sm font-bold text-white">
          Más Popular
        </span>
      )}
      {isComingSoon && (
        <span className="mb-4 inline-block rounded-full bg-[#FFA500] px-3 py-1 text-sm font-bold text-white">
          Próximamente
        </span>
      )}
      <h3 className="mb-4 text-2xl font-bold text-[#2A3E6D]">{title}</h3>
      <div className="mb-6">
        <p className="text-4xl font-bold text-[#4058A0]">{price}</p>
        <p className="text-sm text-gray-500 mt-1">
          <span className="line-through">{originalPrice}</span>
          <span className="ml-2 text-green-600 font-semibold">{discountPercentage}% de descuento</span>
        </p>
      </div>
      <ul className="mb-8 space-y-4">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            {feature.included ? (
              <Check className="mr-2 h-5 w-5 text-green-500" />
            ) : (
              <X className="mr-2 h-5 w-5 text-red-500" />
            )}
            <span className={feature.included ? 'text-[#4058A0]' : 'text-[#999999]'}>
              {feature.text}
            </span>
          </li>
        ))}
      </ul>
      {isComingSoon ? (
        <button disabled className="w-full rounded-md bg-[#999999] py-2 font-bold text-white cursor-not-allowed">
          Próximamente
        </button>
      ) : (
        <button 
          onClick={() => handleSubscribe() } 
          className="w-full rounded-md bg-[#40B3E8] py-2 font-bold text-white transition-colors hover:bg-[#2A3E6D]"
        >
          Seleccionar Plan
        </button>
      )}
    </motion.div>
  )
}

const Pricing = () => {
  const pricingPlan = {
    title: 'Individual',
    price: '$6.990/mes',
    originalPrice: '$9.990/mes',
    discountPercentage: 30,
    features: [
      { text: 'Acceso completo a Lexia', included: true },
      { text: '100 consultas al mes', included: true },
      { text: '1 Usuario', included: true },
      { text: "Leyes", included: true },
      { text: "Doctrina", included: true },
      { text: "Resoluciones (Próximamente)", included: true },
    ],
    isPopular: true,
    isComingSoon: false,
  }

  return (
    <section className="bg-gradient-to-br from-[#E6F3FF] to-white py-20">
      <div className="container mx-auto px-4">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-4 text-center text-4xl font-bold text-[#2A3E6D]"
        >
          Plan de Precios
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="mb-12 text-center text-lg text-[#4058A0]"
        >
          El plan perfecto para tus necesidades
        </motion.p>
        <div className="flex justify-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full max-w-md"
          >
            <PricingCard {...pricingPlan} />
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export default Pricing