import React from "react";
import { UserPlus } from "lucide-react";
import UserTable from "./UserTable";
import AddUserModal from "./AddUserModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

export default function AdminPanel({
  users,
  onAddUser,
  onDeleteUser,
  onToggleUserStatus,
  isAddingUser,
  setIsAddingUser,
  isConfirmingDelete,
  setIsConfirmingDelete,
  confirmDeleteUser,
  authEmail,
}) {
  return (
    <div className="p-6 sm:p-8 bg-gray-50 border-t border-gray-200">
      <h3 className="text-lg font-semibold text-[#4058A0] mb-4">
        Administración de Usuarios
      </h3>
      <div className="space-y-4">
        <button
          onClick={() => setIsAddingUser(true)}
          className="px-4 py-2 bg-[#40B3E8] text-white rounded-md shadow hover:bg-[#3BA2D2] transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#40B3E8] flex items-center"
        >
          <UserPlus className="h-5 w-5 mr-2" />
          Agregar Nuevo Usuario
        </button>
        <UserTable
          users={users}
          onDeleteUser={onDeleteUser}
          onToggleUserStatus={onToggleUserStatus}
          authEmail={authEmail}
        />
      </div>
      {isAddingUser && (
        <AddUserModal onAddUser={onAddUser} onClose={() => setIsAddingUser(false)} />
      )}
      {isConfirmingDelete && (
        <DeleteConfirmationModal
          onConfirm={confirmDeleteUser}
          onCancel={() => setIsConfirmingDelete(false)}
        />
      )}
    </div>
  );
}