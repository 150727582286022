import { motion } from 'framer-motion';
import { ArrowRight, Zap, Target, Puzzle } from 'lucide-react';
import heroImage2 from "../../assets/hero-bg-2.png";
import { useNavigate } from "react-router-dom";
import { postStats } from '../../api/axios';

export default function Description() {
  const navigate = useNavigate();

  const handleNavigatelogin = () => {
    postStats("landing-page", "know-more");
    navigate("/suscripcion");
  };

  const handleNavigateContact = () => {
    postStats("landing-page", "request-demo");
    navigate("/contacto");
  };

  return (
    <section className="bg-gradient-to-b from-[#E6F3FF] to-white py-20" id="description-section">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="mb-16 text-center"
        >
          <h2 className="mb-4 text-3xl font-bold leading-tight tracking-tight text-[#2A3E6D] sm:text-4xl md:text-5xl">
            Optimiza el análisis legal
          </h2>
          <p className="mx-auto max-w-2xl text-xl text-[#4058A0]">
            Lexia optimiza el acceso, análisis y comprensión de leyes, doctrina y resoluciones judiciales, facilitando el trabajo de los profesionales del derecho al agilizar la búsqueda de información y la interpretación de textos jurídicos complejos.
          </p>
        </motion.div>

        <div className="grid gap-12 md:grid-cols-2">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="flex flex-col justify-center"
          >
            <h3 className="mb-6 text-2xl font-semibold text-[#2A3E6D]">¿Qué hace Lexia por ti?</h3>
            <ul className="space-y-4">
              {[
                { icon: Zap, text: "Automatiza la búsqueda y análisis de textos legales complejos para que te concentres en la estrategia." },
                { icon: Target, text: "Accede fácilmente a leyes, doctrina, resoluciones y análisis en una plataforma intuitiva diseñada para profesionales del derecho." },
                { icon: Puzzle, text: "Con Lexia, interpreta y resume los documentos legales más relevantes en cuestión de segundos." }
              ].map((item, index) => (
                <motion.li
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 + 0.3 }}
                  className="flex items-start space-x-3"
                >
                  <item.icon className="mt-1 h-6 w-6 flex-shrink-0 text-[#40B3E8]" />
                  <span className="text-lg text-[#4058A0]">{item.text}</span>
                </motion.li>
              ))}
            </ul>

            {/* Reemplazo del enlace con un botón accesible */}
            <motion.button
              onClick={() => handleNavigatelogin()}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.6 }}
              className="group mt-8 inline-flex items-center text-lg font-medium text-[#40B3E8] hover:text-[#2A3E6D] bg-transparent border-none cursor-pointer"
            >
              Descubre todas las funcionalidades
              <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
            </motion.button>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="relative"
          >
            <div className="aspect-w-16 aspect-h-9 overflow-hidden rounded-lg shadow-xl">
              <img
                src={heroImage2}
                alt="Dashboard del SaaS"
                className="object-cover"
              />
            </div>
            <div className="absolute -bottom-6 -left-6 h-24 w-24 rounded-full bg-[#40B3E8] opacity-50 blur-2xl"></div>
            <div className="absolute -right-6 -top-6 h-24 w-24 rounded-full bg-[#2A3E6D] opacity-50 blur-2xl"></div>
          </motion.div>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
          className="mt-20 rounded-lg bg-gradient-to-r from-[#2A3E6D] to-[#40B3E8] p-8 text-white shadow-lg"
        >
          <div className="flex flex-col items-center space-y-4 text-center sm:flex-row sm:space-x-8 sm:space-y-0 sm:text-left">
            <div className="flex-shrink-0">
              <svg className="h-16 w-16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16V12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 8H12.01" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <div>
              <h3 className="text-2xl font-semibold">¿Aún tienes dudas?</h3>
              <p className="mt-2 text-lg">Agenda una demo personalizada y descubre cómo podemos ayudarte a alcanzar tus objetivos.</p>
            </div>

            {/* Reemplazo del enlace con un botón accesible */}
            <div className="flex-shrink-0">
              <button
                className="inline-flex items-center justify-center rounded-md border-2 border-white bg-white px-5 py-3 text-base font-medium text-[#2A3E6D] transition-colors hover:bg-transparent hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-[#2A3E6D] cursor-pointer"
                onClick={() => handleNavigateContact()}
              >
                Solicitar Demo
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
}