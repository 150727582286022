export const bots = [
    { id: 1, name: "Leyes", avatar: "📜", locked: false, prompts: [
      "Explica la ley de protección al consumidor",
      "¿Cuáles son los derechos laborales básicos?",
      "Resumen de la ley de tránsito"
    ]},
    { id: 2, name: "Doctrina", avatar: "✍🏻", locked: false, prompts: [
      "Explica la teoría del contrato social",
      "¿Qué es el positivismo jurídico?",
      "Principales corrientes del derecho natural"
    ]}
  ];