import React from 'react';
import logoImageWhite from '../../assets/logo-white.png';

import { useNavigate } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  const handleNavigatelogin = () => {
    navigate("/login");
  };

  const handleNavigateContact = () => {
    navigate("/contacto");
  };

  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer className="bg-[#2A3E6D] text-[#E6F3FF]">
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col items-center justify-between space-y-4 md:flex-row md:space-y-0">
          <div className="flex items-center space-x-2">
            <img
              src={logoImageWhite}
              alt="Lexia Logo"
              className="h-10 w-auto object-contain"
            />
          </div>
          <nav className="flex space-x-4">
            {/* Convertimos los enlaces en botones */}
            <button
              className="hover:text-[#40B3E8] transition-colors duration-300 text-white bg-transparent border-none cursor-pointer"
              onClick={() => handleScrollToSection("description-section")}
            >
              Características
            </button>
            <button
              className="hover:text-[#40B3E8] transition-colors duration-300 text-white bg-transparent border-none cursor-pointer"
              onClick={() => handleNavigateContact()}
            >
              Contacto
            </button>
            <button
              className="hover:text-[#40B3E8] transition-colors duration-300 text-white bg-transparent border-none cursor-pointer"
              onClick={() => handleNavigatelogin()}
            >
              Login
            </button>
          </nav>
        </div>
        <div className="mt-8 text-center text-sm">
          <p>&copy; {currentYear} Lexia. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;