import React from "react"
import { ArrowLeftIcon } from "lucide-react"
import { useNavigate } from "react-router-dom"

export default function TermsAndConditions() {
  const navigate = useNavigate()
  const lastUpdated = new Date().toLocaleDateString()

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#E6F3FF] to-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="px-4 py-5 sm:px-6 flex items-center border-b border-gray-200">
          <button onClick={() => navigate("/suscripcion")} className="mr-4">
            <ArrowLeftIcon className="h-6 w-6 text-[#2A3E6D]" />
          </button>
          <h1 className="text-2xl font-bold text-[#2A3E6D]">Términos y Condiciones de Uso de Lexia Spa</h1>
        </div>
        <div className="px-4 py-5 sm:p-6 text-[#2A3E6D] space-y-6">
          <p className="text-sm italic">Última actualización: {lastUpdated}</p>

          <section>
            <h2 className="text-xl font-semibold mb-2">1. Aceptación de los Términos</h2>
            <p>
              Al acceder o utilizar Lexia Spa, el usuario acepta cumplir y estar sujeto a estos Términos y Condiciones. Si no está de acuerdo, debe abstenerse de utilizar nuestros servicios.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">2. Descripción del Servicio</h2>
            <p>
              Lexia Spa ofrece soluciones basadas en inteligencia artificial dirigidas a profesionales del derecho, incluyendo pero no limitándose a la automatización de procesos legales, análisis de datos y gestión de documentos.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">3. Requisitos de Uso</h2>
            <p>
              El usuario debe tener al menos 18 años y poseer la capacidad legal para aceptar estos términos. Los usuarios también son responsables de mantener la confidencialidad de sus credenciales de acceso.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">4. Propiedad Intelectual</h2>
            <p>
              Todos los derechos de propiedad intelectual e industrial sobre Lexia Spa, incluyendo su software, contenidos y marcas, son propiedad de Lexia Spa. Está prohibido utilizar, copiar o distribuir cualquier contenido sin autorización previa.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">5. Limitación de Responsabilidad</h2>
            <p>
              Lexia Spa se ofrece "tal cual" y "según disponibilidad". Lexia Spa no será responsable por daños directos, indirectos, incidentales o consecuentes que puedan derivarse del uso o la incapacidad de uso del servicio, incluso si se ha advertido sobre la posibilidad de tales daños.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">6. Uso Aceptable</h2>
            <p>
              El usuario se compromete a no utilizar Lexia Spa para fines ilegales o prohibidos. También se prohíbe el uso del servicio para enviar spam, virus o cualquier otro código dañino.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">7. Modificaciones al Servicio</h2>
            <p>
              Lexia Spa se reserva el derecho de modificar o interrumpir, temporal o permanentemente, el servicio (o cualquier parte de este) con o sin previo aviso.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">8. Cancelación y Término</h2>
            <p>
              El usuario puede cancelar su acceso a Lexia Spa en cualquier momento. Lexia Spa se reserva el derecho de terminar la cuenta de cualquier usuario que incumpla estos términos.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">9. Indemnización</h2>
            <p>
              El usuario acepta indemnizar y mantener indemne a Lexia Spa, sus afiliados y empleados de cualquier reclamación, daño, pérdida o gasto (incluidos honorarios legales) que surjan de su uso del servicio o la violación de estos términos.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">10. Legislación Aplicable</h2>
            <p>
              Estos términos se rigen por las leyes de Chile y cualquier disputa que surja será resuelta por los tribunales competentes de Santiago.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">11. Cambios a los Términos</h2>
            <p>
              Lexia Spa tiene derecho a modificar estos términos en cualquier momento, y el uso continuado del servicio constituye la aceptación de los términos modificados.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">12. Aceptación de Comunicaciones Electrónicas</h2>
            <p>
              El usuario consiente a recibir comunicaciones electrónicas relacionadas con Lexia Spa y acepta que todas las comunicaciones enviadas de manera electrónica satisfacen cualquier requisito legal de forma escrita.
            </p>
          </section>
        </div>
        <div className="px-4 py-4 sm:px-6 bg-gray-50 border-t border-gray-200">
          <p className="text-sm text-[#2A3E6D]">
            Al utilizar los servicios de Lexia Spa, usted acepta estos Términos y Condiciones. Si tiene alguna pregunta, por favor contáctenos.
          </p>
        </div>
      </div>
    </div>
  )
}