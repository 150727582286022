import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronDown } from 'lucide-react'
import lexiaHomeImage from "../../assets/lexia-home.png";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="mb-4">
      <button
        className="flex w-full items-center justify-between rounded-lg bg-[#E6F3FF] p-4 text-left text-lg font-bold text-[#2A3E6D] transition-colors hover:bg-[#40B3E8] hover:text-white"
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        <ChevronDown
          className={`h-5 w-5 transform transition-transform ${
            isOpen ? 'rotate-180' : ''
          }`}
        />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden"
          >
            <p className="mt-2 p-4 text-[#4058A0]">{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

const FAQSection = () => {
  const faqs = [
    {
      question: "¿Cómo funciona el asistente legal basado en IA?",
      answer: "Nuestro asistente legal utiliza tecnología de inteligencia artificial avanzada para analizar documentos legales, proporcionar respuestas a consultas jurídicas y ayudar en la investigación legal. Procesa y comprende el lenguaje natural, accede a una amplia base de datos de leyes y jurisprudencia, y ofrece respuestas precisas y relevantes en cuestión de segundos."
    },
    {
      question: "¿Qué tipos de consultas legales puede manejar el asistente?",
      answer: "El asistente puede manejar una amplia gama de consultas legales, incluyendo pero no limitado a: interpretación de leyes y regulaciones, búsqueda de precedentes legales, análisis de contratos, y orientación en procedimientos legales básicos. Sin embargo, es importante recordar que el asistente es una herramienta de apoyo y no sustituye el asesoramiento de un abogado calificado."
    },
    {
      question: "¿Cómo se garantiza la confidencialidad de la información?",
      answer: "La confidencialidad es nuestra prioridad. Utilizamos encriptación de alta seguridad para proteger todos los datos ingresados en el sistema. Además, implementamos estrictas políticas de acceso y no almacenamos información personal identificable. Todas las consultas se procesan de forma anónima y segura."
    }
  ]

  return (
    <section className="bg-white py-20">
      <div className="container mx-auto px-4">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-8 text-center text-4xl font-bold text-[#2A3E6D]"
        >
          Preguntas Frecuentes
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="mb-12 text-center text-lg text-[#4058A0]"
        >
          Resolvemos tus dudas sobre nuestro asistente legal basado en IA
        </motion.p>
        <div className="grid gap-8 md:grid-cols-2">
          <div className="order-2 md:order-1">
            {faqs.map((faq, index) => (
              <FAQItem key={index} question={faq.question} answer={faq.answer} />
            ))}
          </div>
          <div className="order-1 md:order-2">
            <motion.img
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              src={lexiaHomeImage}
              alt="Asistente Legal IA"
              className="mx-auto h-auto w-full max-w-md rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FAQSection