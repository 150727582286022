import React, { useState, useEffect } from "react";
import {
  User,
  Edit2,
  Save,
  Mail,
  CreditCard,
  Zap,
  X,
  Plus,
  AlertCircle,
} from "lucide-react";
import {
  getPaymentInfoUser,
  getRemoveUserPayment,
  getUpdatePayment
} from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";

export default function Component({
  name,
  nameTitle,
  email,
  plan,
  isEditingName,
  callsThisMonth,
  maxCallsAllowed,
  onNameChange,
  onToggleNameEdit,
  onSaveName,
}) {
  const [isConfirmingRemove, setIsConfirmingRemove] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { auth } = useAuth();
  const token = auth?.accessToken;

  useEffect(() => {
    getPaymentInfoUser(token)
      .then((response) => {
        console.log("Payment info:", response);
        setIsPaying(response.data.isPaying);
      })
      .catch((error) => {
        console.error("Error al obtener la información de pago:", error);
      });
  }, []);

  const handleRemovePayment = () => {
    setIsConfirmingRemove(true);
  };

  const confirmRemovePayment = () => {
    console.log("Removing payment method...");
    getRemoveUserPayment(token)
      .then((response) => {
        console.log("Payment removed:", response.data);
        setIsPaying(response.data.isPaying);
        setIsConfirmingRemove(false);
      })
      .catch((error) => {
        console.error("Error removing payment:", error);
        setErrorMessage(
          "Hubo un error al eliminar la tarjeta. Por favor, inténtelo de nuevo."
        );
        setIsConfirmingRemove(false);
      });
  };

  const handleAddPayment = () => {
    console.log("Adding payment method...");
    getUpdatePayment(token)
      .then((response) => {
        console.log("Payment added:", response.data.paymentLink);
        window.open(response.data.paymentLink, "_blank");
      })
      .catch((error) => {
        console.error("Error adding payment:", error);
        setErrorMessage(
          "Hubo un error al agregar la tarjeta. Por favor, inténtelo de nuevo."
        );
      });
  };

  return (
    <>
      <div className="p-6 sm:p-8 bg-gradient-to-r from-[#40B3E8] to-[#4058A0]">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <div className="bg-white p-2 rounded-full">
              <User className="h-8 w-8 text-[#40B3E8]" />
            </div>
            <div>
              <h2 className="text-2xl font-bold text-white">{nameTitle}</h2>
              <p className="text-blue-100">Gestiona tu información personal</p>
            </div>
          </div>
          {!isEditingName && (
            <button
              onClick={onToggleNameEdit}
              className="px-4 py-2 bg-white text-[#40B3E8] rounded-md shadow hover:bg-blue-50 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#40B3E8] flex items-center"
            >
              <Edit2 className="h-5 w-5 mr-2" />
              Editar Nombre
            </button>
          )}
        </div>
      </div>
      <div className="p-6 sm:p-8">
        {errorMessage && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            <div className="flex">
              <div className="py-1">
                <AlertCircle className="h-6 w-6 text-red-500 mr-4" />
              </div>
              <div>
                <p className="font-bold">Error</p>
                <p className="text-sm">{errorMessage}</p>
              </div>
            </div>
          </div>
        )}
        <div className="space-y-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <User className="h-6 w-6 text-[#4058A0]" />
              <span className="text-gray-700 font-medium">Nombre</span>
            </div>
            {isEditingName ? (
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  value={name}
                  onChange={onNameChange}
                  className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#40B3E8] focus:border-[#40B3E8]"
                />
                <button
                  onClick={onSaveName}
                  className="px-4 py-2 bg-[#40B3E8] text-white rounded-md shadow hover:bg-[#3BA2D2] transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#40B3E8] flex items-center"
                >
                  <Save className="h-5 w-5 mr-2" />
                  Guardar
                </button>
              </div>
            ) : (
              <span className="text-gray-900 font-semibold">{name}</span>
            )}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <Mail className="h-6 w-6 text-[#4058A0]" />
              <span className="text-gray-700 font-medium">Email</span>
            </div>
            <span className="text-gray-900">{email}</span>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <CreditCard className="h-6 w-6 text-[#4058A0]" />
              <span className="text-gray-700 font-medium">Plan</span>
            </div>
            <span className="text-gray-900">
              {plan === 1000
                ? "Ilimitado"
                : plan === 100
                ? "Demo"
                : "Plan Básico"}
            </span>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <Zap className="h-6 w-6 text-[#4058A0]" />
              <span className="text-gray-700 font-medium">
                Consultas mensuales realizadas
              </span>
            </div>
            <span className="text-gray-900">
              {callsThisMonth || 0} / {maxCallsAllowed || 100}
            </span>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <CreditCard className="h-6 w-6 text-[#4058A0]" />
              <span className="text-gray-700 font-medium">Método de Pago</span>
            </div>
            {isPaying ? (
              <div className="flex items-center space-x-2">
                <button
                  onClick={handleRemovePayment}
                  className="px-4 py-2 bg-red-500 text-white rounded-md shadow hover:bg-red-600 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Remover tarjeta
                </button>
              </div>
            ) : (
              <button
                onClick={handleAddPayment}
                className="px-4 py-2 bg-[#40B3E8] text-white rounded-md shadow hover:bg-[#3BA2D2] transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#40B3E8] flex items-center"
              >
                <Plus className="h-5 w-5 mr-2" />
                Agregar método de pago
              </button>
            )}
          </div>
        </div>
      </div>
      {isConfirmingRemove && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-lg font-semibold mb-4">
              Confirmar remoción de tarjeta
            </h3>
            <p className="mb-6">
              ¿Estás seguro de que deseas remover tu método de pago actual?
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setIsConfirmingRemove(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#40B3E8]"
              >
                Cancelar
              </button>
              <button
                onClick={confirmRemovePayment}
                className="px-4 py-2 bg-red-500 text-white rounded-md shadow hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
