import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Eye, EyeOff, AlertCircle, CheckCircle, ArrowLeft } from 'lucide-react';
import { postResetPassword } from "../../api/axios";

export default function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  useEffect(() => {
    document.title = 'Restablecer Contraseña | Lexia';
  }, []);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrMsg("");
    setSuccessMsg("");

    if (password !== confirmPassword) {
      setErrMsg("Las contraseñas no coinciden");
      return;
    }

    try {
      const response = await postResetPassword({ password, token });
      if (response.status === 200) {
        setSuccessMsg("Contraseña restablecida correctamente. Ahora puedes iniciar sesión.");
        setPassword("");
        setConfirmPassword("");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setErrMsg("Hubo un problema al intentar restablecer la contraseña.");
      }
    } catch (error) {
      setErrMsg(error.response ? error.response.data.message : "Error al restablecer la contraseña");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#40B3E8] to-[#4058A0] flex flex-col justify-center items-center p-4" style={{fontFamily: 'Montserrat, sans-serif'}}>
      <div className="w-full max-w-md">
        <div className="bg-white shadow-2xl rounded-3xl overflow-hidden">
          <div className="px-8 py-12">
            <div className="flex justify-center mb-8">
              <img src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/logo-zNngG1oOXGus0MbQSS36QZCffAawwu.png" alt="Lexia Logo" className="w-48" />
            </div>
            <h2 className="text-3xl font-bold text-[#4058A0] text-center mb-6">Restablecer Contraseña</h2>
            {errMsg && (
              <div className="mb-4 p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 mr-2" />
                  <p>{errMsg}</p>
                </div>
              </div>
            )}
            {successMsg && (
              <div className="mb-4 p-4 bg-green-100 border-l-4 border-green-500 text-green-700">
                <div className="flex">
                  <CheckCircle className="h-5 w-5 mr-2" />
                  <p>{successMsg}</p>
                </div>
              </div>
            )}
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                  Nueva Contraseña
                </label>
                <div className="relative rounded-md shadow-sm">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    required
                    className="block w-full pr-10 border-gray-300 rounded-md focus:ring-[#40B3E8] focus:border-[#40B3E8] text-base h-12 px-4 py-2"
                    placeholder="Ingrese su nueva contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value.trim())}
                  />
                  <button
                    type="button"
                    onClick={toggleShowPassword}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  >
                    {showPassword ? (
                      <EyeOff className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    ) : (
                      <Eye className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    )}
                  </button>
                </div>
              </div>
              <div>
                <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700 mb-1">
                  Confirmar Contraseña
                </label>
                <div className="relative rounded-md shadow-sm">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="confirm-password"
                    name="confirm-password"
                    required
                    className="block w-full pr-10 border-gray-300 rounded-md focus:ring-[#40B3E8] focus:border-[#40B3E8] text-base h-12 px-4 py-2"
                    placeholder="Confirme su nueva contraseña"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value.trim())}
                  />
                  <button
                    type="button"
                    onClick={toggleShowPassword}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  >
                    {showPassword ? (
                      <EyeOff className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    ) : (
                      <Eye className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    )}
                  </button>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-[#40B3E8] to-[#4058A0] hover:from-[#3BA2D2] hover:to-[#3A4F91] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#40B3E8] h-12"
                >
                  Restablecer Contraseña
                </button>
              </div>
            </form>
            <div className="mt-6">
              <Link to="/" className="text-sm font-medium text-[#4058A0] hover:text-[#40B3E8] flex items-center">
                <ArrowLeft className="mr-1 h-4 w-4" />
                Volver a inicio
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}