import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { postContact } from "../../api/axios"
import { Send, ArrowLeft, CheckCircle, XCircle } from "lucide-react"
import logoImage from "../../assets/logo.png"

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
  })
  const [submissionStatus, setSubmissionStatus] = useState(null)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await postContact(
        formData.name,
        formData.email,
        formData.company,
        formData.message
      )
      if (response.status === 201) {
        setSubmissionStatus("success")
      } else {
        setSubmissionStatus("error")
      }
    } catch (error) {
      setSubmissionStatus("error")
    }

    setTimeout(() => {
      setSubmissionStatus(null)
      if (submissionStatus === "success") {
        setFormData({ name: "", email: "", company: "", message: "" })
      }
    }, 3000)
  }

  const feedbackVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  }

  return (
    <section className="bg-gradient-to-br from-[#E6F3FF] to-white py-16">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="max-w-3xl mx-auto"
        >
          <div className="flex justify-between items-center mb-8">
            <motion.a
              href="/"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="flex items-center text-[#2A3E6D] hover:text-[#40B3E8] transition-colors duration-300"
            >
              <ArrowLeft className="mr-2 h-5 w-5" />
              Volver a Inicio
            </motion.a>
            <img src={logoImage} alt="Lexia Logo" className="h-12 w-auto" />
          </div>
          <h2 className="text-3xl font-bold text-center mb-8 text-[#2A3E6D]">
            Contáctanos
          </h2>
          <form
            onSubmit={handleSubmit}
            className="bg-white shadow-md rounded-lg p-8 relative"
          >
            <AnimatePresence>
              {submissionStatus && (
                <motion.div
                  key="feedback"
                  variants={feedbackVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  className={`absolute top-0 left-0 right-0 p-4 text-white text-center ${
                    submissionStatus === "success" ? "bg-green-500" : "bg-red-500"
                  }`}
                >
                  {submissionStatus === "success" ? (
                    <div className="flex items-center justify-center">
                      <CheckCircle className="mr-2 h-5 w-5" />
                      Mensaje enviado correctamente
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <XCircle className="mr-2 h-5 w-5" />
                      Error al enviar mensaje
                    </div>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
            <div className="mb-6">
              <label
                htmlFor="name"
                className="block text-[#4058A0] text-sm font-bold mb-2"
              >
                Nombre
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-[#E6F3FF] rounded-md focus:outline-none focus:ring-2 focus:ring-[#40B3E8]"
                placeholder="Tu nombre"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block text-[#4058A0] text-sm font-bold mb-2"
              >
                Correo electrónico
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-[#E6F3FF] rounded-md focus:outline-none focus:ring-2 focus:ring-[#40B3E8]"
                placeholder="tu@email.com"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="company"
                className="block text-[#4058A0] text-sm font-bold mb-2"
              >
                Empresa / Estudio
              </label>
              <input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-[#E6F3FF] rounded-md focus:outline-none focus:ring-2 focus:ring-[#40B3E8]"
                placeholder="Nombre de tu empresa o estudio"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="message"
                className="block text-[#4058A0] text-sm font-bold mb-2"
              >
                Mensaje
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows={4}
                className="w-full px-3 py-2 border border-[#E6F3FF] rounded-md focus:outline-none focus:ring-2 focus:ring-[#40B3E8]"
                placeholder="Tu mensaje aquí..."
              ></textarea>
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className="w-full bg-[#2A3E6D] text-white font-bold py-3 px-4 rounded-md hover:bg-[#40B3E8] transition-colors duration-300 flex items-center justify-center"
            >
              <Send className="mr-2 h-5 w-5" />
              Enviar Mensaje
            </motion.button>
          </form>
        </motion.div>
      </div>
    </section>
  )
}

export default Contact