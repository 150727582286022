import Login from "./components/app/Login";
import Home from "./components/app/Home";
import LandingPage from "./components/landingPage/LandingPage";
import Unauthorized from "./components/app/Unauthorized";
import Missing from "./components/app/Missing";
import RequireAuth from "./components/app/RequireAuth";
import PersistLogin from "./components/app/PersistLogin";
import Contact from "./components/landingPage/Contact";
import Perfil from "./components/app/Perfil";
import Repositorio from "./components/app/Repository";
import SuscripcionForm from "./components/landingPage/SubscriptionForm";
import Verify from "./components/landingPage/Verify";
import TermsAndConditions from "./components/landingPage/TermsAndConditions";
import ForgotPassword from "./components/app/ForgotPassword";
import ResetPassword from "./components/app/ResetPassword";

import { Routes, Route } from "react-router-dom";

const ROLES = {
  admin: 300,
  moredator: 200,
  user: 100,
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="login" element={<Login />} />
      <Route path="unauthorized" element={<Unauthorized />} />
      <Route path="contacto" element={<Contact />} />
      <Route path="suscripcion" element={<SuscripcionForm />} />
      <Route path="verify" element={<Verify />} />
      <Route path="terminos-y-condiciones" element={<TermsAndConditions />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route element={<PersistLogin />}>
        <Route
          element={<RequireAuth allowedRoles={[ROLES.user, ROLES.moredator, ROLES.admin]} />}
        >
          <Route path="home" element={<Home />} />
          <Route path="repositorio" element={<Repositorio />} />
          <Route path="perfil" element={<Perfil />} />
        </Route>
      </Route>
      <Route path="*" element={<Missing />} />
    </Routes>
  );
}

export default App;
