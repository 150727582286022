import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { postForgotPassword } from "../../api/axios";
import { ArrowLeft, Mail, AlertCircle, CheckCircle } from 'lucide-react';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    document.title = 'Recuperar Contraseña | Lexia';
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrMsg('');
    setSuccessMsg('');

    if (email === '') {
      setErrMsg('Por favor, ingresa tu correo electrónico');
      return;
    }

    try {
      const response = await postForgotPassword(email);
      if (response.status === 200) {
        setSuccessMsg('Se ha enviado un enlace de recuperación a tu correo electrónico.');
        setEmail('');
        setFormSubmitted(true);
      } else {
        setErrMsg('Hubo un problema al intentar recuperar la contraseña. Inténtalo nuevamente.');
      }
    } catch (error) {
      setErrMsg(error.response ? error.response.data.message : 'Error al recuperar la contraseña');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#40B3E8] to-[#4058A0] flex flex-col justify-center items-center p-4" style={{fontFamily: 'Montserrat, sans-serif'}}>
      <div className="w-full max-w-md">
        <div className="bg-white shadow-2xl rounded-3xl overflow-hidden">
          <div className="px-8 py-12">
            <div className="flex justify-center mb-8">
              <img src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/logo-zNngG1oOXGus0MbQSS36QZCffAawwu.png" alt="Lexia Logo" className="w-48" />
            </div>
            <h2 className="text-3xl font-bold text-[#4058A0] text-center mb-6">Recupera tu contraseña</h2>
            {errMsg && (
              <div className="mb-4 p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 mr-2" />
                  <p>{errMsg}</p>
                </div>
              </div>
            )}
            {successMsg && (
              <div className="mb-4 p-4 bg-green-100 border-l-4 border-green-500 text-green-700">
                <div className="flex">
                  <CheckCircle className="h-5 w-5 mr-2" />
                  <p>{successMsg}</p>
                </div>
              </div>
            )}
            {!formSubmitted ? (
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                    Correo electrónico
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Mail className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      autoComplete="email"
                      required
                      className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-[#40B3E8] focus:border-[#40B3E8] sm:text-sm"
                      placeholder="tu@ejemplo.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value.trim())}
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-[#40B3E8] to-[#4058A0] hover:from-[#3BA2D2] hover:to-[#3A4F91] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#40B3E8]"
                  >
                    Enviar enlace de recuperación
                  </button>
                </div>
              </form>
            ) : null}
            <div className="mt-6 flex items-center justify-between">
              <Link to="/" className="text-sm font-medium text-[#4058A0] hover:text-[#40B3E8] flex items-center">
                <ArrowLeft className="mr-1 h-4 w-4" />
                Volver a inicio
              </Link>
              <Link to="/login" className="text-sm font-medium text-[#4058A0] hover:text-[#40B3E8]">
                Inicia sesión
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}