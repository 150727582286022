import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Zap, BarChart2, Users } from 'lucide-react'

const FeatureCard = ({ icon: Icon, title, description, color }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <motion.div
      className={`relative overflow-hidden rounded-xl bg-white p-6 shadow-xl transition-all duration-300 ease-in-out ${
        isHovered ? 'scale-105' : ''
      }`}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className={`absolute -right-4 -top-4 h-24 w-24 rounded-full bg-${color}-100 opacity-50 blur-xl`} />
      <div className={`mb-4 inline-block rounded-full bg-${color}-100 p-3`}>
        <Icon className={`h-8 w-8 text-${color}-600`} />
      </div>
      <h3 className="mb-2 text-xl font-bold text-[#2A3E6D]">{title}</h3>
      <p className="text-[#4058A0]">{description}</p>
      <motion.div
        className="absolute bottom-0 left-0 h-1 bg-gradient-to-r from-[#2A3E6D] to-[#40B3E8]"
        initial={{ width: '0%' }}
        animate={{ width: isHovered ? '100%' : '0%' }}
        transition={{ duration: 0.3 }}
      />
    </motion.div>
  )
}

const InnovativeFeatures = () => {
  const features = [
    {
      icon: Zap,
      title: 'Automatización Jurídica',
      description: 'Agiliza la búsqueda de leyes, doctrina y resoluciones con Lexia, ahorrando tiempo en el análisis legal. Puedes crear documentos legales en minutos.',
      color: 'blue',
    },
    {
      icon: BarChart2,
      title: 'Análisis Legal Inteligente',
      description: 'Obtén resúmenes claros y precisos de textos legales, mejorando la toma de decisiones informadas. Analiza tus documentos con Lexia para tener mejores insights.',
      color: 'blue',
    },
    {
      icon: Users,
      title: 'Colaboración Eficiente',
      description: 'Accede y comparte información jurídica con tu equipo de manera ágil, sin importar la ubicación.',
      color: 'blue',
    },
  ]

  return (
    <section className="bg-gradient-to-br from-[#E6F3FF] to-white py-20">
      <div className="container mx-auto px-4">
        <motion.h2
          className="mb-12 text-center text-4xl font-bold text-[#2A3E6D]"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Características Revolucionarias
        </motion.h2>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default InnovativeFeatures